import { deliveryNoticeService } from '@/api';
import { Paging } from '@/api/base';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DeliveryModeEnum, DeliveryStatusEnum, LogisticsOrderStatusEnum } from '@/resource/enum';
import {
  DeliveryNoticeDetail,
  DeliveryShopItemRelList,
  DeliveryShopList,
  LogisticsList,
  ShopListQuery
} from '@/resource/model';
import { dateFormat, fuzzyQueryStr, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'DeliveryNoticeDetails'
})
export default class DeliveryNoticeDetails extends Vue {
  public deliveryNotice: DeliveryNoticeDetail | null = null;

  public currentDeliveryShopName = '';

  public currentDeliveryProduct: Array<DeliveryShopItemRelList> = [];

  /**
   * 门店明细查询条件
   */
  public queryForm: Partial<ShopListQuery> = {
    address: []
  };
  /**
   * 过滤后的数据，在没有任何筛选条件生效时，该数据必须保证与tableData一致
   */
  public filteredData: Array<Partial<DeliveryShopList>> = [];
  public queryItemsOption: Array<OsQueryItemOption<ShopListQuery>> = [
    {
      type: 'Slot',
      slotName: 'address',
      field: 'address',
      label: 'addDeliveryNotice.deliveryAddress',
      option: {}
    }
  ];

  public shopTableOptions: OsTableOption<Partial<DeliveryShopList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public shopColumnOptions: Array<OsTableColumn<DeliveryShopList>> = [
    {
      prop: 'shopName',
      label: 'addDeliveryNotice.deliverySite',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'addDeliveryNotice.deliveryAddress',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as DeliveryShopList).province}
        ${(row as DeliveryShopList).city ? (row as DeliveryShopList).city : ''}
        ${(row as DeliveryShopList).district ? (row as DeliveryShopList).district : ''}
        ${(row as DeliveryShopList).address}`;
      }
    },
    {
      prop: 'receiver',
      label: 'addDeliveryNotice.receiver',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiverTel',
      label: 'addDeliveryNotice.receiverTel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendMethod',
      label: 'logistics.deliveryMode',
      showOverflowTooltip: true,
      minWidth: '120px',
      formatter: (row: Object): string => {
        if (!DeliveryModeEnum[(row as DeliveryShopList).sendMethod]) {
          return '--';
        }
        return translation(`deliveryMode.${DeliveryModeEnum[(row as DeliveryShopList).sendMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'logistics.deliveryRemark',
      showOverflowTooltip: true,
      minWidth: '200px'
    }
  ];

  public shopPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public shopTotal = 0;

  public productPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public productTotal = 0;

  public productTableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public productColumnOptions: Array<OsTableColumn<DeliveryShopItemRelList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as DeliveryShopItemRelList).finishWidth} × ${(row as DeliveryShopItemRelList).finishHeight}`;
      }
    },
    {
      prop: 'productCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'addDeliveryNotice.sendCount',
      minWidth: '180px'
    },
    {
      prop: 'planDeliveryTime',
      label: 'projectShop.plannedShippingTime',
      minWidth: '180px',
      formatter: (row: Object): string => {
        return dateFormat((row as DeliveryShopItemRelList).planDeliveryTime);
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public logisticsTableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    size: 'mini'
  };

  public logisticsColumnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'childCount',
      label: 'logistics.childCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'sendMethod',
      label: 'logistics.logisticsInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'sender',
      label: 'logistics.senderInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as LogisticsList).createTime);
      }
    }
  ];

  public logisticsPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public id = Number(this.$route.query.id);

  public logisticsTotal = 0;

  private needReload = false;
  /**
   * 同步filteredData 和 tableData的数据
   */
  public synchronizationData(): void {
    this.filteredData = this.deliveryNotice?.shopList || [];
  }

  public filterData(): void {
    this.synchronizationData();
    if (this.queryForm.address![0]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![0]!, x.province!));
    }
    if (this.queryForm.address![1]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![1]!, x.city!));
    }
    if (this.queryForm.address![2]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![2]!, x.district!));
    }
    this.shopPagingData();
  }

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.initDeliveryNoticeInfo(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.initDeliveryNoticeInfo(this.id);
    }
  }

  public get getStatusName(): string {
    return translation(`deliveryNoticeStatus.${DeliveryStatusEnum[this.deliveryNotice!.status]}`);
  }

  public get allowPush(): boolean {
    return this.deliveryNotice?.status === DeliveryStatusEnum.new;
  }

  public getDeliveryMode(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  public shopClick(deliveryShop: DeliveryShopList): void {
    this.currentDeliveryProduct = deliveryShop.shopItemReList;
    this.currentDeliveryShopName = ` —— ${deliveryShop.shopName}`;
    this.productPagingData();
  }

  public shopPagingData(): void {
    this.shopTotal = this.filteredData.length || 0;
    this.shopTableOptions.data =
      this.filteredData.slice(
        (this.shopPaging.currentPage - 1) * this.shopPaging.showCount,
        this.shopPaging.currentPage * this.shopPaging.showCount
      ) || [];
    if (this.shopTotal > 0) {
      this.shopClick(this.shopTableOptions.data[0] as any);
    }
  }

  public productPagingData(): void {
    this.productTotal = this.currentDeliveryProduct.length;
    this.productTableOptions.data = this.currentDeliveryProduct.slice(
      (this.productPaging.currentPage - 1) * this.productPaging.showCount,
      this.productPaging.currentPage * this.productPaging.showCount
    );
  }

  public logisticsPagingData(): void {
    this.logisticsTotal = this.deliveryNotice?.logisticsOrderVOList.length ?? 0;
    this.logisticsTableOptions.data =
      this.deliveryNotice?.logisticsOrderVOList.slice(
        (this.logisticsPaging.currentPage - 1) * this.logisticsPaging.showCount,
        this.logisticsPaging.currentPage * this.logisticsPaging.showCount
      ) ?? [];
  }

  public pushDeliveryNotice(): void {
    deliveryNoticeService
      .batchPush([this.deliveryNotice!.id])
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.needReload = true;
        this.initDeliveryNoticeInfo(this.id);
      })
      .catch(error => {
        messageError(error);
      });
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/delivery-notice') {
      to.meta!.needReload = this.needReload;
    }
    next();
  }

  /**
   * 获取物流单状态class
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.inTransit:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  /**
   * 获取物流单状态名称
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return 'common.unKnownStatus';
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public getLogisticsReceiverAdress(logistics: LogisticsList): string {
    return `${logistics.receivingProvince ?? ''}${logistics.receivingCity ?? ''} ${logistics.receivingDistrict ??
      ''}${logistics.receivingAddress ?? ''}`;
  }

  public getLogisticsSenderAdress(logistics: LogisticsList): string {
    return `${logistics.sendProvince ?? ''}${logistics.sendCity ?? ''}${logistics.sendDistrict ??
      ''}${logistics.senderAddress ?? ''}`;
  }

  public linkToLogisticsDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  private initDeliveryNoticeInfo(id: number): void {
    deliveryNoticeService
      .getById(id)
      .then(res => {
        this.deliveryNotice = res;
        this.filterData();
        this.logisticsPagingData();
      })
      .catch(error => {
        messageError(error);
      });
  }
}
